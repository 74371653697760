import { default as Image } from '@react/shared/components/Image';
import AgentBadge from 'sly/agentsRegionPage/AgentsRegionPageContent/AgentsRegionPageData/AgentsRegionPageAgents/AgentTile/ProfileImage/AgentBadge';
import { ProfileImageProps } from 'sly/agentsRegionPage/AgentsRegionPageContent/AgentsRegionPageData/AgentsRegionPageAgents/AgentTile/ProfileImage/ProfileImage.types';

export default function ProfileImage({
  src,
  recentFamiliesHelped,
  displayName,
}: ProfileImageProps) {
  return (
    <div
      className='relative flex h-0 w-full items-center justify-center overflow-hidden rounded rounded-bl-none rounded-br-none pt-[66%]'
      data-tp-id='gen-div-c01b1c93-8dd3-4351-adc2-1549e1d20485'
    >
      <Image
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        src={src}
        layout='raw'
        height={200}
        width={200}
        className='absolute top-0 left-0 h-full w-full object-cover'
        alt={`${displayName} profile image`}
        data-tp-id='gen-Image-33574bea-7462-45bc-a268-658268bcc648'
      />
      {recentFamiliesHelped && (
        <AgentBadge>
          <b>{recentFamiliesHelped}</b> families helped
        </AgentBadge>
      )}
    </div>
  );
}
