import IconItem from '@react/shared/components/IconItem';
import {
  ProfileContentLiWrapperProps,
  ProfileContentProps,
} from 'sly/agentsRegionPage/AgentsRegionPageContent/AgentsRegionPageData/AgentsRegionPageAgents/AgentTile/ProfileContent/ProfileContent.types';
import Location from 'sly/icons/Location';
import Star from 'sly/icons/Star';

function LiWrapper({ children }: ProfileContentLiWrapperProps) {
  return (
    <li className='mb-2 list-none break-inside-avoid-column last:mb-0'>
      {children}
    </li>
  );
}

export default function ProfileContent({
  displayName,
  rating,
  operatingIn,
}: ProfileContentProps) {
  return (
    <div
      className='border-[1px] border-t-0 border-[#E9EBED] p-6'
      data-tp-id='gen-div-dfe0f615-3031-46cf-9960-c84255c9e6d1'
    >
      <div
        className='mb-2 text-xl font-medium leading-[1.6]'
        data-tp-id='gen-div-eaa67635-44ee-45b9-85df-d71a90e7a0c9'
      >
        {displayName}
      </div>
      <ul className='m-0 mb-6 p-0'>
        {rating.numRatings > 0 && (
          <LiWrapper>
            <IconItem Icon={Star}>
              <>
                {Number.isInteger(rating.ratingValue)
                  ? rating.ratingValue
                  : rating.ratingValue.toFixed(1)}{' '}
                from <span>{rating.numRatings} reviews</span>
              </>
            </IconItem>
          </LiWrapper>
        )}
        <LiWrapper>
          <IconItem Icon={Location}>{operatingIn}</IconItem>
        </LiWrapper>
      </ul>
    </div>
  );
}
