import React, { forwardRef, Ref } from 'react';
import { SimpleIconProps } from '../icon.types';
import Icon from './Icon';

const svg = `<svg viewBox="0 0 24 24" fill="currentColor">
  <path d="M14.4413 21.5913C14.0022 22.0304 13.4634 22.25 12.825 22.25C12.1866 22.25 11.6478 22.0304 11.2087 21.5913L2.4087 12.7913C2.19747 12.5801 2.03071 12.3335 1.90842 12.0516C1.78614 11.7697 1.725 11.4692 1.725 11.15V4.00001C1.725 3.37427 1.94773 2.83867 2.3932 2.39321C2.83867 1.94774 3.37427 1.72501 4 1.72501H11.15C11.4692 1.72501 11.7697 1.78615 12.0516 1.90843C12.3335 2.03071 12.5801 2.19747 12.7913 2.40871L21.5913 11.2277C22.0304 11.6668 22.25 12.2024 22.25 12.8345C22.25 13.4666 22.0304 14.0022 21.5913 14.4413L14.4413 21.5913ZM12.831 20L19.981 12.85L11.15 4.00001H4V11.15L12.831 20ZM6.5 8.00001C6.91667 8.00001 7.27083 7.85417 7.5625 7.56251C7.85417 7.27084 8 6.91667 8 6.50001C8 6.08334 7.85417 5.72917 7.5625 5.43751C7.27083 5.14584 6.91667 5.00001 6.5 5.00001C6.08333 5.00001 5.72917 5.14584 5.4375 5.43751C5.14583 5.72917 5 6.08334 5 6.50001C5 6.91667 5.14583 7.27084 5.4375 7.56251C5.72917 7.85417 6.08333 8.00001 6.5 8.00001Z"/>
</svg>`;

const Offer = forwardRef(
  (props: SimpleIconProps, ref: Ref<SVGSVGElement> | undefined) => (
    <Icon ref={ref} name='offer' svg={svg} {...props} />
  )
);

Offer.displayName = 'OfferIcon';

export default Offer;
