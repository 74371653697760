import { AgentBadgeProps } from 'sly/agentsRegionPage/AgentsRegionPageContent/AgentsRegionPageData/AgentsRegionPageAgents/AgentTile/ProfileImage/AgentBadge/AgentBadge.types';

export default function AgentBadge({ children }: AgentBadgeProps) {
  return (
    <div
      className='absolute top-4 left-0 rounded-none rounded-tr-3xl rounded-br-3xl bg-viridian-base p-2 text-[0.875rem] leading-[1.4] text-white'
      data-tp-id='gen-div-941282fa-af96-46a6-be51-951dd3ebf0a9'
    >
      {children}
    </div>
  );
}
