import { Agent } from '@react/shared/types/agents';
import EntityTile from '@sly/frontend/react/card';
import { useState } from 'react';
import AgentTile from 'sly/agentsRegionPage/AgentsRegionPageContent/AgentsRegionPageData/AgentsRegionPageAgents/AgentTile';
import { BoostedCommunity } from 'sly/hubPage/components/HubDynamicContent/ExploreByEntity/ExploreByCommunitySection/ExploreByCommunitySection.types';
import { Arrow } from 'sly/icons';
import clsxm from 'sly/lib/clsxm';

import { Entity } from '../ExploreByCommunity.types';

export default function ExploreByCommunitySection({
  entityData,
  active,
  tab,
  entity,
}: {
  entityData: BoostedCommunity[] | Agent[];
  active: boolean;
  tab: string;
  entity: Entity;
}) {
  const [showMore, setShowMore] = useState(false);
  const onClick = () => {
    setShowMore(!showMore);
  };
  return (
    <div
      className={clsxm(
        active ? '' : 'hidden',
        'w-fullmd:w-[720px] mx-auto mt-6 mb-20 lg:w-[1032px]'
      )}
    >
      <div className='mb-6 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3'>
        {entity === 'communities'
          ? entityData
              ?.slice(0, showMore ? undefined : 6)
              .map((community) => (
                <EntityTile
                  key={community.id}
                  entity={community}
                  intent='vertical'
                />
              ))
          : entityData
              ?.slice(0, showMore ? undefined : 6)
              .map((agent) => (
                <AgentTile agent={agent as Agent} key={agent.id} />
              ))}
      </div>
      {entityData?.length > 6 && (
        <span
          className='cursor-pointer text-viridian-base underline'
          onClick={onClick}
        >
          Show {showMore ? 'fewer' : 'more'} {tab} {entity}{' '}
          <Arrow className={!showMore ? 'rotate-180' : ''} />
        </span>
      )}
    </div>
  );
}
