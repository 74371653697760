/** @jsxImportSource react */

import { SimpleIconProps } from '@sly/icons/Icon';
import React, {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from 'react';

export interface IconItemProps {
  children: React.ReactNode;
  Icon: ForwardRefExoticComponent<
    PropsWithoutRef<SimpleIconProps & RefAttributes<SVGSVGElement>>
  >;
  borderless?: boolean;
}

export default function IconItem({
  children,
  Icon,
  borderless = true,
}: IconItemProps) {
  const borderLessClass = borderless
    ? 'mr-1 pl-0 border-0'
    : 'border-[1px] border-[#D0D2D4] rounded';
  return (
    <div
      className='flex items-baseline'
      data-tp-id='gen-div-6fd920e9-8075-4269-8733-b7fc23e50e91'
    >
      <div
        className={`mr-4 py-0 px-[calc(0.5rem-1px)] ${borderLessClass}`}
        data-tp-id='gen-div-91c5a752-9687-4415-8f76-9300f0ca374e'
      >
        <Icon />
      </div>
      <div
        className='text-sm leading-[1.429] text-slate-base'
        data-tp-id='gen-div-7d39000d-88b2-4411-980a-759cd1effa3d'
      >
        {children}
      </div>
    </div>
  );
}
