import { STRAPI_CTA_TYPES } from '@sly/core/constants/strapi';
import { eventNames } from 'sly/services/events';

export const getEventProps = (ctaProps, rest) => {
  const { type = '', text: buttonText } = ctaProps || {};
  let cta;

  switch (type) {
    case STRAPI_CTA_TYPES.assessmentWizard: {
      cta = 'Open assessment wizard';
      break;
    }
    case STRAPI_CTA_TYPES.bot: {
      cta = 'Trigger bot';
      break;
    }
    case STRAPI_CTA_TYPES.path: {
      cta = 'Go to custom path';
      break;
    }
    case STRAPI_CTA_TYPES.typeform_wizard: {
      cta = 'Open typeform wizard';
      break;
    }
    case STRAPI_CTA_TYPES.getExpertHelp: {
      cta = 'Get expert help';
      break;
    }
    case STRAPI_CTA_TYPES.gwizzy: {
      cta = 'Gwizzy Wizard';
      break;
    }
    case STRAPI_CTA_TYPES.scheduleTourSpecific: {
      cta = 'Schedule Tour (Tour Specific CTA)';
      break;
    }
    case STRAPI_CTA_TYPES.scheduleTourHybrid: {
      cta = 'Schedule Tour (Gwizzy Hybrid CTA)';
      break;
    }
    default:
      cta = 'Invalid Cta Type';
      break;
  }

  return {
    eventProps: {
      ...rest,
      cta,
      text: buttonText,
    },
  };
};

export const getTypeformProps = ({
  city,
  state,
  communitySlug = '',
  mode = { cta: '', entry: '' },
  name = 'assessmentWizardPA',
  stateUrlParam,
  cityUrlParam,
  nonInteraction = 0,
  ...rest
}) => {
  return {
    isTypeform: true,
    typeformHiddenFields: {
      slug: communitySlug,
      city,
      state,
      state_url_param: stateUrlParam,
      city_url_param: cityUrlParam,
    },
    typeformStartEventArgs: [
      eventNames.TypeformWizardStarted,
      {
        name: name,
        cta: mode?.cta,
        entry: mode?.entry,
        communitySlug,
        city,
        state,
        nonInteraction,
      },
    ],
    typeformSubmitEventArgs: [
      eventNames.TypeformWizardSubmitted,
      {
        name: name,
        cta: mode?.cta,
        entry: mode?.entry,
        communitySlug,
        city,
        state,
      },
    ],
    ...rest,
  };
};
